import { getServiceableAddressId } from '@/redux/actions';
import store from '@/redux/store';
import { getCartItemId } from './getCartItemId';
import { getPincodeAddressId } from './getPincodeAddressId';

export function triggerGetServiceableAddressId() {
  const storeData = store.getState().storeReducer.store;
  const userData = store.getState().userReducer;
  if (!userData?.addressList?.length) return;
  const payload = {
    item_ids: getCartItemId(),
    pincode_address_ids: getPincodeAddressId(),
    store_id: storeData.store_id,
  };
  if (payload.item_ids?.length) store.dispatch(getServiceableAddressId(payload));
}
