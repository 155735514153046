import CustomInput from './CustomInput';

const CustomFields = ({
  customFieldsList,
  setCustomFieldList,
  state,
  invalidInputIdsMap,
}) => {
  function isCustomFieldVisible() {
    return !!(
      customFieldsList?.length &&
      customFieldsList.some((field) => field?.custom_field_value?.visibility)
    );
  }

  if (!isCustomFieldVisible()) {
    return null;
  }

  function handleInputChange(id, text) {
    const updatedCustomFieldList = customFieldsList?.map((field) => {
      if (field?.id === id) {
        return {
          ...field,
          custom_field_value: {
            ...field.custom_field_value,
            text: text,
          },
        };
      }
      return field;
    });
    setCustomFieldList(updatedCustomFieldList);
  }

  return (
    <div>
      {customFieldsList?.map((field) => (
        <CustomInput
          key={field?.id}
          field={field}
          invalidInputIdsMap={invalidInputIdsMap}
          handleInputChange={handleInputChange}
        />
      ))}
    </div>
  );
};

export default CustomFields;
