export const DropDownArrow = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '15.691'}
      height={props.height || '8.949'}
      viewBox="0 0 15.691 8.949"
    >
      <path
        id="Path_132908"
        fill={props.fillColor || '000000'}
        data-name="Path 132908"
        d="M172.146,128.242a.842.842,0,0,0-1.263.093,1.048,1.048,0,0,0,.085,1.384l6.389,6.126-6.389,6.126a1.047,1.047,0,0,0-.085,1.384.842.842,0,0,0,1.263.093l7.159-6.865a1.044,1.044,0,0,0,0-1.477Zm0,0"
        transform="translate(143.69 -170.661) rotate(90)"
      />
    </svg>
  );
};
