import Input from 'src/components/Common/Premium/Input';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import { FIELDS_ID, VALIDATION_TYPES } from '../../Constants';
import {
  GenderDropDownArrow,
  GenderDropDownContainer,
  GenderDropDownElementContainer,
} from '../../AddDelivery.styles';
import { DropDownArrow } from 'src/assets/svgExports/DropDownArrow';
import { useEffect, useRef, useState } from 'react';
import { GENDER_TYPES } from 'src/utils/constants';
import useIsClickOutside from 'src/utils/useIsClickOutside';

const AgeAndGenderInput = ({
  state,
  handleInputChange,
  setGender,
  invalidInputIdsMap,
}) => {
  const [showGenderDropDown, setShowGenderDropDown] = useState(false);
  const genderDropDownRef = useRef(null);

  useIsClickOutside(genderDropDownRef, setShowGenderDropDown, false);

  useEffect(() => {
    showGenderDropDown && genderDropDownRef.current.scrollIntoView();
  }, [showGenderDropDown]);

  function handleShowGenderDropDown(e = null) {
    e?.stopPropagation();
    setShowGenderDropDown(!showGenderDropDown);
  }

  function handleGenderClick(value, e) {
    handleShowGenderDropDown(e);
    setGender(value);
  }

  function renderGenderDropDown() {
    return (
      <GenderDropDownContainer>
        {Object.values(GENDER_TYPES).map((value, index) => (
          <GenderDropDownElementContainer
            key={index}
            className="tw-pb-[8px] tw-pt-[3px]"
            onClick={(e) => {
              handleGenderClick(value, e);
            }}
          >
            {value}
          </GenderDropDownElementContainer>
        ))}
      </GenderDropDownContainer>
    );
  }

  return (
    <div className={`flex ${IS_DESKTOP ? 'flex-row' : 'flex-column'}`}>
      {state?.selectedAddress?.age?.visibility && (
        <Input
          marginBottom={IS_DESKTOP ? 0 : 40}
          label={`Age ${!state?.selectedAddress?.age?.is_mandatory ? '(optional)' : '*'}`}
          type="text"
          id={FIELDS_ID.Age}
          className={invalidInputIdsMap[FIELDS_ID.Age] ? '!tw-border-b-[#F63D60]' : ''}
          labelClasses={invalidInputIdsMap[FIELDS_ID.Age] ? '!tw-text-[#F63D60]' : ''}
          value={state.age}
          onChange={(e) =>
            handleInputChange('age', e.target.value, VALIDATION_TYPES.NUMERIC, 0, 3)
          }
        />
      )}
      {state?.selectedAddress?.gender?.visibility && (
        <div className="flex w-100 relative flex-column" ref={genderDropDownRef}>
          <div
            onClick={(e) => handleShowGenderDropDown(e)}
            className={`flex flex-row w-100 relative ${IS_DESKTOP ? 'ml20px' : ''}`}
          >
            <Input
              label={`Gender ${
                !state?.selectedAddress?.gender?.is_mandatory ? '(optional)' : '*'
              }`}
              type="text"
              id={FIELDS_ID.Gender}
              className={
                invalidInputIdsMap[FIELDS_ID.Gender] ? '!tw-border-b-[#F63D60]' : ''
              }
              labelClasses={
                invalidInputIdsMap[FIELDS_ID.Gender] ? '!tw-text-[#F63D60]' : ''
              }
              isBlinkingCursorVisible={true}
              marginBottom={0}
              value={state.gender}
              onChange={(e) =>
                handleInputChange(
                  'gender',
                  e.target.value,
                  VALIDATION_TYPES.ALPHA_NUMERIC,
                  0,
                  100
                )
              }
            />
            <GenderDropDownArrow showGenderDropDown={showGenderDropDown}>
              <DropDownArrow fill={'#000000'} />
            </GenderDropDownArrow>
          </div>
          {showGenderDropDown && renderGenderDropDown()}
        </div>
      )}
    </div>
  );
};

export default AgeAndGenderInput;
