import styled from 'styled-components';

export const DSInput = styled.div`
  width: 100%;
  ${(props) =>
    props?.marginBottom !== undefined
      ? `margin-bottom: ${props?.marginBottom}px`
      : `margin-bottom: 40px`};
  position: relative;
`;

export const FormControl = styled.input`
  &,
  &:focus,
  &:hover {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #c3c3c3;
    color: #000;
    outline: none;
    position: relative;
    z-index: 2;
    box-shadow: none;
    background-color: transparent;
    width: 100%;
    padding: 5px;

    &::placeholder {
      opacity: 0.7;
    }
  }

  ${(props) => (props?.isBlinkingCursorVisible ? 'caret-color: transparent' : '')};

  &:disabled,
  &[readonly] {
    background-color: #e9ecef;
    opacity: 0.5;
  }

  & + label {
    position: absolute;
    top: -35px;
    left: 11px;
    transition: 0.2s all;
    z-index: 1;
    color: ${(props) => props.fontColor};
    font-size: 12px;
  }

  &:not(:empty) + label,
  &:focus + label {
    top: -35px;
    font-size: 12px;
    color: ${(props) => props.fontColor};
  }

  &[value=''] + label {
    top: -16px;
    left: 6px;
    color: #999;
    font-size: 14px;
  }
`;
