export function Work(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        d="M16.4 6.2h-3.2V4.6A1.594 1.594 0 0011.6 3H8.4a1.594 1.594 0 00-1.6 1.6v1.6H3.6a1.588 1.588 0 00-1.592 1.6L2 16.6a1.594 1.594 0 001.6 1.6h12.8a1.594 1.594 0 001.6-1.6V7.8a1.594 1.594 0 00-1.6-1.6zm-4.8 0H8.4V4.6h3.2z"
        fill={props.color || '#000000'}
      />
    </svg>
  );
}
