import { Location } from '@/assets/svgExports/Location';
import { Work } from '@/assets/svgExports/Work';
import { Home } from '@/assets/svgExports/Home';
import { getThemeColor } from 'src/utils/getThemeColor';
import { ADDRESS_NAMES, ADDRESS_TYPES } from '../../Constants';
import { useEffect, useState } from 'react';
import Input from 'src/components/Common/Premium/Input';

const AddressTypesAndName = ({ state, setState }) => {
  const themeColor = getThemeColor();

  const [showOtherInputField, setShowOtherInputField] = useState(false);

  useEffect(() => {
    setShowOtherInputField(state.addressType === ADDRESS_TYPES.OTHERS);
  }, [state.addressType]);

  const addressTypesData = [
    {
      Icon: Home,
      type: ADDRESS_TYPES.HOME,
      name: ADDRESS_NAMES.HOME,
    },
    {
      Icon: Work,
      type: ADDRESS_TYPES.WORK,
      name: ADDRESS_NAMES.WORK,
    },
    {
      Icon: Location,
      type: ADDRESS_TYPES.OTHERS,
      name: ADDRESS_NAMES.OTHERS,
    },
  ];

  return (
    <div className="premium-address-container__typewrap premium-wrapper-around">
      <div className="tw-flex tw-w-full tw-flex-col tw-gap-[6px] md:tw-flex-row">
        <div className="form-group btn-group">
          {addressTypesData.map(({ Icon, type, name }, index) => {
            const isSelected = state.addressType === type;
            const border = isSelected ? `1px solid ${themeColor}` : `1px dashed #ccd2de`;
            const color = isSelected ? themeColor : '#000000';

            return (
              <button
                key={index}
                className="pointer"
                type="button"
                onClick={() => {
                  setState({
                    ...state,
                    addressType: type,
                    addressName: name,
                  });
                  setShowOtherInputField(type === ADDRESS_TYPES.OTHERS);
                }}
                style={{
                  border,
                  color,
                }}
              >
                <Icon color={color || '#9e9e9e'} />
                {name}
              </button>
            );
          })}
        </div>
        {showOtherInputField && (
          <div className="premium-address-container__wrap premium-wrapper-around tw-mt-[12px] !tw-p-0">
            <Input
              marginBottom="0"
              label={'Enter location nickname'}
              type="text"
              value={state.addressName}
              onChange={(e) => {
                const value = e.target.value;
                if (value.trim().length <= 18) {
                  setState({
                    ...state,
                    addressName: value,
                  });
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AddressTypesAndName;
