import { getStringifyAddress } from './getStringifyAddress';
import store from '@/redux/store';

/**
 * returns array of Pincode mapped with corresponding address_id
 * @returns {array} pincode and address_id array
 */

export function getPincodeAddressId() {
  const pincode_address_id = [];
  const userData = store.getState().userReducer;
  for (let i = 0; i < userData?.addressList?.length; i++) {
    const addressDetails = getStringifyAddress(userData?.addressList?.[i]);
    pincode_address_id.push({
      pincode: addressDetails?.pincode,
      address_id: addressDetails?.address_id,
    });
  }
  return pincode_address_id;
}
