import styled from 'styled-components';

export const SaveAddressCtaContainer = styled.div`
  display: flex;
  justify-content: center;
  background: white;
  justify-content: flex-end;
  margin: 0px 16px 16px 20px;
`;

export const SaveAddressCta = styled.div`
  display: flex;
  background: ${(props) => props.color || '#FAFAFA'};
  color: white;
  padding: 16px 32px;
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 16px;
  font-width: 600;
  border-radius: ${(props) => props.borderRadius};
  cursor: pointer;
  width: 100%;
  justify-content: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 0px;
  }
`;

export const GenderDropDownArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const GenderDropDownArrow = styled.div`
  transform: ${(props) =>
    props.showGenderDropDown
      ? 'translate(-15px, 4px) rotate(180deg)'
      : 'translate(-15px, 4px) rotate(0deg)'};
  cursor: pointer;
  z-index: 2;
  height: fit-content;
  width: fit-content;
`;

export const GenderDropDownContainer = styled.div`
  display: flex;
  margin-left: 1px;
  width: 100%;
  position: absolute;
  z-index: 3;
  background : white;
  transform: translate(7px,25px);
  flex-direction: column;
  border: 1px solid #EAEAEC;
  border-radius: 8px;
 
  @media screen and (max-width : 767px){
    width 87.5vw;
    transform: translate(-2px,26px);
  }
`;

export const CounntryDropDownContainer = styled.div`
  display: flex;
  padding: 12px 0px;
  margin-left: 1px;
  width: 90%;
  position: absolute;
  z-index: 3;
  background: white;
  transform: translate(0px, 25px);
  flex-direction: column;
  border: 1px solid #eaeaec;
  border-radius: 8px;
  overflow: scroll;
  max-height: 20vh;
`;

export const DropDownElementContainer = styled.div`
  padding-left: 14px;
  padding-right: 6px;
  font-size: 14px;
  padding-bottom: 4px;
  padding-top: 4px;
  ${(props) => (props.selected ? 'background-color: #bfbfbf;' : '')}
  :hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }
`;

export const GenderDropDownElementContainer = styled.div`
  padding-left: 14px;
  padding-right: 6px;
  font-size: 14px;
  padding-bottom: ${(props) => props?.pb && props?.pb}px;
  padding-top: ${(props) => props?.pt && props?.pt}px;
  margin-top: ${(props) => props?.mt && props?.mt}px;
  margin-bottom: ${(props) => props?.mb && props?.mb}px;
  :hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }
`;
