import React, { useState, useEffect } from 'react';
import Modal from '../../Common/Modal';
import { useSelector } from 'react-redux';
import { deviceWidth } from '@/utils/deviceWidth';
import { Button } from '../AtomicComponents';
import getCustomCtaText from '@/utils/getCustomCtaText';

const timingDuration = 3;

const OrderConfirmLoader = (props) => {
  const [confirmStatus, setConfirmStatus] = useState({
    isConfirmed: false,
    confirmCount: 0,
  });
  const [timeLeft, setTimeLeft] = useState(timingDuration);

  const storeInfo = useSelector((state) => state.storeReducer.store);
  const cart = useSelector((state) => state.cartReducer);

  useEffect(() => {
    if (!timeLeft && !confirmStatus.isConfirmed) {
      props.submitAction();
      props.closeModal();
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, confirmStatus.isConfirmed]);

  useEffect(() => {
    if (props.isVisible) {
      document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
    } else {
      document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
    }
  }, [props.visible]);

  const confirmButtonClick = () => {
    props.submitAction();
    props.closeModal();
  };

  const cancelButtonClick = () => {
    setConfirmStatus((confirmStatus) => ({
      ...confirmStatus,
      isConfirmed: !confirmStatus.isConfirmed,
      confirmCount: confirmStatus.confirmCount + 1,
    }));
  };

  return (
    props.isVisible && (
      <Modal
        visible={props.isVisible}
        customStyles={{
          paddingLeft: 0,
          paddingRight: 0,
          maxWidth: deviceWidth <= 766 ? '100%' : 480,
        }}
        animation={'slideUp'}
        className="bottom-modal"
      >
        <div className="OrderConfirmLoader">
          <div className="payment-section" style={{ border: 'none' }}>
            {confirmStatus.isConfirmed && (
              <span className="payment-closeicon" onClick={() => props.closeModal()}>
                <img loading="lazy" src="/assets/images/cancel.png" alt="cancel" />
              </span>
            )}
            <h2>{getCustomCtaText('placing_order_cta')}</h2>
          </div>
          <div className="payment-section" style={{ flexDirection: 'column' }}>
            <p className="info-header info-header--main">Select payment option</p>
            <div className="payment-selection">
              <div className="payment-selection__radio">
                <span
                  className="payment-selection__radio--icon"
                  style={{
                    backgroundColor: storeInfo?.theme?.colors?.primary_color,
                  }}
                >
                  <span
                    className="payment-selection__radio--icon-inner"
                    style={{
                      backgroundColor: storeInfo?.theme?.colors?.primary_color,
                    }}
                  />
                </span>
              </div>
              <div className="payment-selection__info">
                <h3 className="payment-selection__info--header">
                  {getCustomCtaText('')}
                </h3>
                <div className="payment-selection__info--amount">
                  <span className="payment-selection__info--amount-text">
                    Total Amount:
                  </span>
                  <span
                    className="payment-selection__info--amount-price"
                    style={{
                      color: storeInfo?.theme?.colors?.primary_color,
                    }}
                  >
                    ₹{cart.buyer_pay_value}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="payment-section">
            {confirmStatus.isConfirmed ? (
              <Button
                roundness={storeInfo?.theme?.roundness}
                backgroundColor={storeInfo?.theme?.colors?.primary_color}
                py="15px"
                px="10px"
                fontSize={18}
                fluid={true}
                label="Place Order"
                fontColor="#ffffff"
                onClick={confirmButtonClick}
              />
            ) : (
              <div className="payment-cancellation">
                <div className="payment-cancellation__bar">
                  <div
                    className="payment-cancellation__bar--animated"
                    style={{
                      backgroundColor: storeInfo?.theme?.colors?.primary_color,
                      animationDuration: `${timingDuration}s`,
                    }}
                  />
                </div>
                <div className="payment-cancellation__btn">
                  <Button
                    variant="outline"
                    roundness={storeInfo?.theme?.roundness}
                    backgroundColor={storeInfo?.theme?.colors?.primary_color}
                    py="11px"
                    px="18px"
                    fontSize="15"
                    fluid={true}
                    label="Cancel"
                    fontColor="#ffffff"
                    onClick={cancelButtonClick}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    )
  );
};

export default OrderConfirmLoader;
