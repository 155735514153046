export function Location(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        d="M9.6 2A5.6 5.6 0 004 7.6C4 11.8 9.6 18 9.6 18s5.6-6.2 5.6-10.4A5.6 5.6 0 009.6 2zm0 7.6a2 2 0 112-2 2 2 0 01-2 2z"
        fill={props.color || '#000000'}
      />
    </svg>
  );
}
