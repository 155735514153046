export function Home(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        d="M8.4 16v-4.588h3.2V16h4V9.882H18L10 3 2 9.882h2.4V16z"
        fill={props.color || '#000000'}
      />
      <path d="M0 0h20v20H0z" fill="none" />
    </svg>
  );
}
