import React from 'react';
import { useSelector } from 'react-redux';
import { DSInput, FormControl } from './Input.styles';

const Input = (props) => {
  const { theme } = useSelector((state) => state.storeReducer);

  return (
    <DSInput marginBottom={props?.marginBottom}>
      <FormControl {...props} fontColor={theme?.colors?.primary_color} />
      <label className={`${props?.labelClasses || '!tw-left-[5px]'} `}>
        {props.label}
      </label>
    </DSInput>
  );
};

export default Input;
