import store from '../redux/store';

/**
 * @returns {array} item Id's inside Cart.
 */
export function getCartItemId() {
  const item_id = [];
  const cartData = store.getState().cartReducer;
  for (let i = 0; i < cartData?.items?.length; i++) {
    item_id.push(cartData?.items?.[i]?.item_id);
  }
  return item_id;
}
