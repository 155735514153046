import { DEFAULT_ADDRESS_ID } from './constants';

/**
 * add delivery info in cart
 * @param {*} cart
 * @param {*} address
 * @return {*} cartWithDeliveryInfo
 */
export const addDeliveryInfoInCart = (cart, address) => {
  return {
    ...cart,
    delivery_info: {
      deliver_to: address.user_name,
      address1: address.address1,
      address2: address.address2,
      pincode: address.pincode,
      city: address.city,
      state: address.state,
      country: address.country,
      landmark: address.landmark,
      address_id: address.address_id ? address.address_id : DEFAULT_ADDRESS_ID,
      latitude: address.latitude,
      longitude: address.longitude,
      alternate_phone: address.alternate_phone,
      email_id: address.email_id,
      gender: address.gender,
      age: address.age,
      custom_field_data_list: address?.custom_field_data_list ?? [],
    },
  };
};
