import { useState } from 'react';
import Input from '@/components/Common/Premium/Input';
import { FIELDS_ID } from '../../Constants';
const CustomInput = ({ field, invalidInputIdsMap, handleInputChange }) => {
  const [input, setInput] = useState(field?.custom_field_value?.text || '');
  return (
    <Input
      label={`${field?.custom_field_name} ${
        !field?.custom_field_value?.is_mandatory ? '(optional)' : '*'
      }`}
      type="text"
      id={FIELDS_ID.CustomFieldValue}
      className={
        invalidInputIdsMap[`${FIELDS_ID.CustomFieldValue}${field?.id}`]
          ? '!tw-border-b-[#F63D60]'
          : ''
      }
      labelClasses={
        invalidInputIdsMap[`${FIELDS_ID.CustomFieldValue}${field?.id}`]
          ? '!tw-text-[#F63D60]'
          : ''
      }
      value={input}
      onChange={(e) => {
        setInput(e.target.value);
        handleInputChange(field.id, e.target.value);
      }}
    />
  );
};

export default CustomInput;
