import store from '../redux/store';

export function getCustomFieldPayload(customFieldDataList) {
  if (!Array.isArray(customFieldDataList) || !customFieldDataList.length) {
    return [];
  }
  return customFieldDataList.map((field) => ({
    custom_field_name: field?.custom_field_name || '',
    custom_field_value: field?.custom_field_value?.text || '',
  }));
}

export function getStringifyAddress(address = '') {
  const userData = store.getState().userReducer;
  if (!address) {
    const savedAddress = userData?.savedAddress;
    // Update the cached saved address with latest fetched address data
    const latestSavedAddress = userData?.addressList?.find(
      (add) => add?.address_id === savedAddress?.address_id
    );
    if (latestSavedAddress) {
      address = latestSavedAddress;
    } else {
      address = savedAddress;
    }
  }
  const stringifyAddress = {
    ...address,
    user_name: address?.user_name?.text ?? address?.user_name ?? '',
    landmark: address?.landmark?.text ?? address?.landmark ?? '',
    pincode: address?.pincode?.text ?? address?.pincode ?? '',
    city: address?.city?.text ?? address?.city ?? '',
    state: address?.state?.text ?? address?.state ?? '',
    country: address?.country?.text ?? address?.country ?? '',
    address1: address?.address1?.text ?? address?.address1 ?? '',
    address2: address?.address2?.text ?? address?.address2 ?? '',
    address_id: address?.address_id,
    latitude: address?.latitude ?? 0,
    longitude: address?.longitude ?? 0,
    alternate_phone: address?.alternate_phone?.text ?? address?.alternate_phone ?? '',
    email_id: address?.email_id?.text ?? address?.email_id ?? '',
    gender: address?.gender?.text ?? address?.gender ?? '',
    age: address?.age?.text ?? address?.age ?? '',
    custom_field_data_list: getCustomFieldPayload(address?.custom_field_data_list),
  };
  return stringifyAddress;
}
