import Input from 'src/components/Common/Premium/Input';
import {
  CounntryDropDownContainer,
  DropDownElementContainer,
  GenderDropDownArrow,
} from '../../AddDelivery.styles';
import { DropDownArrow } from 'src/assets/svgExports/DropDownArrow';
import { CircularProgress } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCountriesList } from 'src/redux/actions';
import useIsClickOutside from 'src/utils/useIsClickOutside';

const CountryInput = ({
  country,
  INDIA_COUNTRY_OBJECT,
  setCountry,
  countriesData,
  setCountriesData,
}) => {
  const dispatch = useDispatch();
  const [countriesLoading, setCountriesLoading] = useState(false);
  const [showCountryDropDown, setShowCountryDropDown] = useState(false);

  const countryDropDownRef = useRef(null);
  const countryElementId = (id) => `country-${id}`;

  useIsClickOutside(countryDropDownRef, setShowCountryDropDown, false);

  useEffect(() => {
    handleCountryDropdown();
  }, [showCountryDropDown]);

  function handleShowCountryDropDown(e = null) {
    e?.stopPropagation();
    setShowCountryDropDown(!showCountryDropDown);
  }

  const handleCountryDropdown = () => {
    if (showCountryDropDown) {
      countryDropDownRef.current?.scrollIntoView?.();
      if (countriesData.countries.length === 0) {
        setCountriesLoading(true);
        dispatch(fetchCountriesList({}, fetchCountriesCallback));
      } else {
        scrollToCountry(countriesData?.defaultCountry?.id);
      }
    }
  };

  const fetchCountriesCallback = (countriesList, defaultCountry) => {
    setCountriesLoading(false);
    if (country && country !== defaultCountry?.country) {
      defaultCountry =
        countriesList?.find((countryData) => country === countryData?.country) ||
        defaultCountry;
    }

    setCountriesData({
      countries: countriesList,
      defaultCountry: defaultCountry,
    });

    /**
     * setTimeout is used to defer the 'scrollToCountry' function.
     * This will ensure that 'scrollToCountry' is called after re-rendering is done after 'setCountriesData'
     */
    setTimeout(() => {
      scrollToCountry(defaultCountry?.id);
    }, 0);
  };

  const scrollToCountry = (countryId) => {
    const countryDropdownElement = document.getElementById(countryElementId(countryId));
    countryDropdownElement && countryDropdownElement.scrollIntoView();
  };

  function onSelectCountry(country, e) {
    handleShowCountryDropDown(e);
    setCountry(country);
    setCountriesData((countriesData) => ({
      ...countriesData,
      defaultCountry: country,
    }));
  }

  function renderCountryDropDown() {
    return (
      <CounntryDropDownContainer>
        {countriesLoading ? (
          <div className="flex justify-center items-center-l pa3">
            <CircularProgress />
          </div>
        ) : (
          (countriesData.countries.length > 0
            ? countriesData.countries
            : [INDIA_COUNTRY_OBJECT]
          ).map((country, index) => {
            return (
              <DropDownElementContainer
                id={countryElementId(country?.id)}
                key={index}
                selected={countriesData.defaultCountry?.id === country?.id}
                onClick={(e) => onSelectCountry(country, e)}
              >
                {country?.country}
              </DropDownElementContainer>
            );
          })
        )}
      </CounntryDropDownContainer>
    );
  }
  return (
    <div className="flex w-100 relative flex-column mb4-ns" ref={countryDropDownRef}>
      <div
        onClick={(e) => handleShowCountryDropDown(e)}
        className={`flex flex-row w-100 `}
      >
        <Input
          label={'Country *'}
          type="text"
          id="country"
          isBlinkingCursorVisible={true}
          marginBottom={0}
          value={country}
          style={{ width: '90%' }}
        />
        <GenderDropDownArrow showGenderDropDown={showCountryDropDown}>
          <DropDownArrow fill={'#000000'} />
        </GenderDropDownArrow>
      </div>
      {showCountryDropDown && renderCountryDropDown()}
    </div>
  );
};

export default CountryInput;
